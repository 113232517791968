.nfl-hero--subtitle{
  .cmp-text{
    margin: 0;

    p{
      margin: 0;
      text-align: center;
      color: $white;
      font-family: $font-family-light;
      font-size: 24px;
      line-height: 30px;
      max-width: 80%;
      margin: auto;

      @media (min-width: $tablet){
        font-size: 34px;
        line-height: 38px;
        max-width: 60%;        
      }
      
      @media (min-width: $desktop){    
        font-size: 36px;    
        line-height: 62px;
      }
    }
  }
}

.nfl-club-hero{
  &__container{
    .nfl-hero--subtitle{
      .cmp-text{
        p{
          @media (min-width: $desktop){          
            text-align: left;
            max-width: none;
            line-height: 40px;
          }
        }
      }
    }
  }
}

.nfl-home-hero--text {
  .cmp-text {
    margin: 0;

    * {
      font-weight: 400;
      font-style: normal;
      text-align: center;
      color: $white;
      padding: 36px 34px 20px;
      font-size: 40px;
      line-height: 42px;

      @media (min-width: $tablet) {
        padding: 40px 1px 22px;
        font-size: 45.7947px;
        line-height: 53px;
      }

      @media (min-width: $desktop) {
        padding: 42px 299px 5px 301px;
        font-size: 54px;
        line-height: 62px;
      }
    }
  }
}
